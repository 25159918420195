// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activate-account-js": () => import("./../../../src/pages/activate-account.js" /* webpackChunkName: "component---src-pages-activate-account-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-batch-calendar-js": () => import("./../../../src/pages/batch-calendar.js" /* webpackChunkName: "component---src-pages-batch-calendar-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-case-studies-akshat-siddharth-js": () => import("./../../../src/pages/case-studies/akshat-siddharth.js" /* webpackChunkName: "component---src-pages-case-studies-akshat-siddharth-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-manimegalan-js": () => import("./../../../src/pages/case-studies/manimegalan.js" /* webpackChunkName: "component---src-pages-case-studies-manimegalan-js" */),
  "component---src-pages-common-interview-questions-with-answers-js": () => import("./../../../src/pages/common-interview-questions-with-answers.js" /* webpackChunkName: "component---src-pages-common-interview-questions-with-answers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contests-calculator-js": () => import("./../../../src/pages/contests/calculator.js" /* webpackChunkName: "component---src-pages-contests-calculator-js" */),
  "component---src-pages-contests-contest-1-js": () => import("./../../../src/pages/contests/contest1.js" /* webpackChunkName: "component---src-pages-contests-contest-1-js" */),
  "component---src-pages-contests-js": () => import("./../../../src/pages/contests.js" /* webpackChunkName: "component---src-pages-contests-js" */),
  "component---src-pages-contests-random-quotes-js": () => import("./../../../src/pages/contests/random-quotes.js" /* webpackChunkName: "component---src-pages-contests-random-quotes-js" */),
  "component---src-pages-courses-full-stack-web-development-online-courses-js": () => import("./../../../src/pages/courses/full-stack-web-development-online-courses.js" /* webpackChunkName: "component---src-pages-courses-full-stack-web-development-online-courses-js" */),
  "component---src-pages-cramclass-js": () => import("./../../../src/pages/cramclass.js" /* webpackChunkName: "component---src-pages-cramclass-js" */),
  "component---src-pages-cramcode-explore-js": () => import("./../../../src/pages/cramcode/explore.js" /* webpackChunkName: "component---src-pages-cramcode-explore-js" */),
  "component---src-pages-cramcode-js": () => import("./../../../src/pages/cramcode.js" /* webpackChunkName: "component---src-pages-cramcode-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-full-stack-developer-course-syllabus-2020-updated-js": () => import("./../../../src/pages/full-stack-developer-course-syllabus-2020-updated.js" /* webpackChunkName: "component---src-pages-full-stack-developer-course-syllabus-2020-updated-js" */),
  "component---src-pages-full-stack-web-dev-online-live-training-js": () => import("./../../../src/pages/full-stack-web-dev-online-live-training.js" /* webpackChunkName: "component---src-pages-full-stack-web-dev-online-live-training-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-hire-from-us-index-js": () => import("./../../../src/pages/hire-from-us/index.js" /* webpackChunkName: "component---src-pages-hire-from-us-index-js" */),
  "component---src-pages-hiring-partners-js": () => import("./../../../src/pages/hiring-partners.js" /* webpackChunkName: "component---src-pages-hiring-partners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexbk-js": () => import("./../../../src/pages/indexbk.js" /* webpackChunkName: "component---src-pages-indexbk-js" */),
  "component---src-pages-modal-previewimage-js": () => import("./../../../src/pages/modal/previewimage.js" /* webpackChunkName: "component---src-pages-modal-previewimage-js" */),
  "component---src-pages-modal-youtube-js": () => import("./../../../src/pages/modal-youtube.js" /* webpackChunkName: "component---src-pages-modal-youtube-js" */),
  "component---src-pages-newsroom-events-js": () => import("./../../../src/pages/newsroom/events.js" /* webpackChunkName: "component---src-pages-newsroom-events-js" */),
  "component---src-pages-newsroom-index-js": () => import("./../../../src/pages/newsroom/index.js" /* webpackChunkName: "component---src-pages-newsroom-index-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payment-receipt-js": () => import("./../../../src/pages/payment-receipt.js" /* webpackChunkName: "component---src-pages-payment-receipt-js" */),
  "component---src-pages-placement-consultancy-in-chennai-js": () => import("./../../../src/pages/placement-consultancy-in-chennai.js" /* webpackChunkName: "component---src-pages-placement-consultancy-in-chennai-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-self-paced-js": () => import("./../../../src/pages/self-paced.js" /* webpackChunkName: "component---src-pages-self-paced-js" */),
  "component---src-pages-software-training-institute-in-chennai-js": () => import("./../../../src/pages/software-training-institute-in-chennai.js" /* webpackChunkName: "component---src-pages-software-training-institute-in-chennai-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contest-js": () => import("./../../../src/templates/contest.js" /* webpackChunkName: "component---src-templates-contest-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-cramcode-js": () => import("./../../../src/templates/cramcode.js" /* webpackChunkName: "component---src-templates-cramcode-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-no-data-js": () => import("./../../../src/templates/no-data.js" /* webpackChunkName: "component---src-templates-no-data-js" */),
  "component---src-templates-product-graphql-js": () => import("./../../../src/templates/product-graphql.js" /* webpackChunkName: "component---src-templates-product-graphql-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */),
  "component---src-templates-with-context-js": () => import("./../../../src/templates/with-context.js" /* webpackChunkName: "component---src-templates-with-context-js" */)
}

