import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/global.css"
import "./src/styles/footer.css"
import "./src/styles/home.css"
import "./src/styles/blog.css"
import "./src/styles/testimonial.css"
import "./src/styles/youtube.css"
import "./src/styles/course.css"
import "./src/styles/contest.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"